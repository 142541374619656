<template>
    <div>
        <div class="SecondTitle">
            <span>{{ $t("common.welcome") }}</span>
        </div>
        <div class="tips" 
            v-html='$t("enterEmail.tips")'
        ></div>
        <van-cell-group class="form-cell" style="margin-top: 20px">
            <van-form>
                <div style="margin-top: 15px">
                    <van-field
                        required
                        :placeholder="this.$t('jpnRegistration.email')"
                        v-model="email"
                        :error-message="errMessageEmail"
                        @input="validatorEmail()"
                />
                </div>

                <van-divider :style="{ borderColor: '#000', margin: '0' }" />
                
                <van-button
                    :disabled="check() || this.loading"
                    size="large"
                    round
                    native-type="submit"
                    class="button-getotp"
                    @click="searchCustomer()"
                    :loading="loading"
                >{{ $t("common.check") }}</van-button>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Cell,
    CellGroup,
    Form,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    NumberKeyboard,
    PasswordInput,
    Toast,
    Dialog
} from "vant";
import { Image as VanImage } from "vant";
import { searchCustomer, getSession } from "@/api/eformApi";
import mixins from "./mixin/Registration.js";
export default {
    name: "EnterEmail",
    mixins: [mixins],
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Form.name]: Form,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [VanImage.name]: VanImage,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            email: "",
            loading: false,
            errMessageEmail: "",
            emailRegex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[.][a-zA-Z]{2,3}$/,
        };
    },
    computed: {
        currentLocale() {
            return this.$root.$i18n.locale;
        },
    },
    watch: {
        currentLocale: {
            handler() {
                if (this.errMessageEmail != "") {
                    this.validatorEmail();
                }
            },
        },
    },
    methods: {
        validatorEmail() {
            this.errMessageEmail = "";
            if (!this.emailRegex.test(this.email.trim())) {
                this.errMessageEmail = this.$t("jpnRegistration.errMessageEmail");
                return false;
            }
            return true;
        },
        searchCustomer() { 
            this.loading = true;
            getSession({
                country: this.$route.params.countryPath,
                email: this.email,
            }).then(res => {
                if (res.success) {
                    this.doSearchCustomer(res.data)
                } else {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("enterEmail.toastFail"),
                    });
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    message: this.$t("enterEmail.toastFail"),
                });
                this.loading = false;
            });
        },
        doSearchCustomer(sessionKey) {
            searchCustomer({
                country: this.$route.params.countryPath,
                brandCode: this.$route.params.brand,
                email: this.email,
                storeNo: this.$route.query.storeId,
                sessionKey: sessionKey,
            }).then((res) => {
                if (res.success) {
                    if (res.data.exist) {
                        Dialog.alert({
                            message: this.$t("jpnRegistration.alreadyRegistered"),
                            confirmButtonText: this.$t("common.confirm")
                        }).then(() => {
                            this.$router.push({
                                name: 'QRcodes',
                                params: {
                                    custId: res.data.data.consumerID,
                                    storeId: this.$route.query.storeId,
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            })
                            this.loading = false
                        })
                    } else {
                        Toast.success({
                            type: "success",
                            message: this.$t("enterEmail.toastSuccess"),
                        });
                        this.$router.push(
                            {
                                name: 'Registration',
                                params: {
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath,
                                    sessionKey: sessionKey,
                                    email: this.email,
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            }
                        );
                        this.loading = false
                    }
                } else {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("enterEmail.toastFail"),
                    });
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    message: this.$t("enterEmail.toastFail"),
                });
                this.loading = false;
            });
        },
        check() {
            switch (this.$route.params.countryPath) {
                case "JPN":
                    return this.email.trim() == "" || this.errMessageEmail != ""
                default:
                    return true;
            }
        },
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

.form-cell >>> .van-hairline, .van-hairline--bottom, .van-hairline--left, .van-hairline--right, .van-hairline--surround, .van-hairline--top, .van-hairline--top-bottom{
    position: initial;
}
>>> .van-popup--top {
    width: 25%;
    padding-left: 16px;
}

.button-getotp {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 4%;
}

>>> .van-password-input__item {
    border-radius: 5px;
}
.custom-class {
    color: #ff0000;
}

.phone_class >>> .van-field__control {
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
}

</style>
<style>
.noticeWidth {
    width: 100%;
}
</style>
